.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.btn-primary {
  background-color: #3b6289 !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.modal-max-800 {
  width: 100%;
  max-width: 800px !important;
}

.modal-max-1000 {
  width: 100%;
  max-width: 1000px !important;
}

.date-input-container .input-group-text {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-right: 0px !important;
}

.date-input-container input {
  border-top-left-radius: 1px !important;
  border-bottom-left-radius: 1px !important;
  max-width: 300px;
}

.sub-menu {
  display: flex;
  border: 2px solid #dee2e6;
  width: fit-content;
}

.sub-menu .sub-menu-item {
  background-color: #dee2e6;
  cursor: pointer;
  user-select: none;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 25px;
  padding-right: 25px;
}

.sub-menu .active .sub-menu-item {
  background-color: #f2f2f3;
  cursor: default;
}

.sub-menu a {
  text-decoration: none;
  color: #212529;
}

.indented-text {
  margin-left: 10px;
  margin-top: 10px;
}

.option-group {
  margin-bottom: 20px;
}

.accordion .student-submission-header span {
  margin-right: 20px;
}

.accordion .student-submission-header span {
  margin-right: 20px;
}

.result-text {
  font-weight: bold;
}

.result-text.pending {
  color: #6c757d;
}

.result-text.failed {
  color: #dc3545;
}

.result-text.success, .result-text.submitted {
  color: #28a745;
}

.accordion .accordion-body {
  margin-top: 15px;
}

.code-container {
  background-color: #2f3338;
  width: 100%;
  border-radius: 5px;
  color: #ededed;
}

.code-container code pre {
  color: #ededed;
  font-size: 16px;
  padding: 20px;
  margin-top: 20px;
}

.assignment-settings {
  background-color: #F2F2F3;
  border-radius: 5px;
  padding: 30px !important;
  margin-right: 30px;
  margin-left: 10px;
}

.link {
  color: #0d6efd;
  cursor: pointer;
  text-decoration: underline;
}
